import { defineStore } from 'pinia'
import { formatISO, format } from 'date-fns'
import { useMainStore } from '~/stores/main'
import useHelpers from '~/composables/useHelpers'
import useFrontDoorApi from '~/composables/useFrontDoorApi'

import Course = models.server.api.sitecore.course.Course
import MuleCourse = models.server.api.curriculum.course.Course
import CurriculumCourse = models.server.api.curriculum.course.Course
import SearchResult = models.server.api.curriculum.courseSearch.SearchResult
import CourseSearchResult = models.server.api.curriculum.courseSearch.Course
import { validateCourseFields } from '~/server/utils/tempSitecoreValidation'

interface SearchQuery {
  query?: string
  filters: string
}

export const useCourseStore = defineStore('course', () => {
  const { isExpired } = useHelpers()

  const items = ref<Course[]>([])
  const muleCourseItems = ref<CurriculumCourse[]>([])
  const searchHistory = ref<Record<string, CourseSearchResult[]>>({})
  const createdTracker = ref<Record<string, string>>({})

  const getOne = computed(() => (courseId: string) => {
    checkExpiry(courseId)
    return (items.value.find(({ id }) => id === courseId) || {}) as Course
  })

  // not including mule courses in expiry check
  const getMuleCourseById = computed(
    () => (courseId: string) =>
      muleCourseItems.value.find(({ id }) => id === courseId) || {}
  )

  const courseExists = computed(() => (courseId: string) => {
    checkExpiry(courseId)
    return items.value.findIndex(({ id }) => id === courseId) !== -1
  })

  // not including mule courses in expiry check
  const muleCourseExists = computed(
    () => (courseId: string) =>
      muleCourseItems.value.findIndex(({ id }) => id === courseId) !== -1
  )

  async function fetchOne(id?: string) {
    if (!id) return

    checkExpiry(id)

    // if the course already exists in state don't perform the API request
    if (courseExists.value(id)) return getOne.value(id)

    const { fetch } = useFrontDoorApi()

    const course = await fetch<Course>(
      `/api/course/${id}?include=waitlists`,
      `/api/ssc/e2e.courses/course/${id}?include=waitlists`,
      (data) => {
        const result = validateCourseFields(data)

        // invert API info to 'positive anchor' for fee-free status, and remove API field to keep single source of truth
        if (
          result.additionalInformation &&
          Object.hasOwn(result.additionalInformation, 'excludeFeeFree')
        ) {
          result.isFeeFreeEligible =
            !result.additionalInformation.excludeFeeFree
          delete result.additionalInformation.excludeFeeFree
        }

        return result
      }
    )

    if (!!(course && Object.keys(course).length)) {
      createdTracker.value[course.id || id] = formatISO(new Date())
      items.value.push(course)
    }

    return course
  }

  async function fetchMuleCourseById(id: string | undefined) {
    if (!id) return

    // check if course is already fetched
    if (muleCourseExists.value(id)) return getMuleCourseById.value(id)

    // get the course using mule soft api
    const mainStore = useMainStore()
    const url = `${mainStore.origin}/api/course/mule-course/${id}`
    const course = await $fetch<CurriculumCourse>(url)

    if (course && Object.keys(course).length) {
      muleCourseItems.value.push(course)
      return course
    }

    return {}
  }

  async function fetchCourseRollup(id: string) {
    const mainStore = useMainStore()
    const today = format(Date.now(), 'yyyy-MM-dd')
    const url = `${mainStore.origin}/api/course/mule-course/${id}`
    const query = `offering.webPublishStartDate<="${today}";offering.webPublishEndDate>="${today}"`

    try {
      return await $fetch<MuleCourse>(url, {
        query: {
          filter: query
        }
      })
    } catch {
      return null
    }
  }

  async function search({ filters, query }: SearchQuery) {
    const tag = `${query || 'no-query'}//${filters}`

    if (searchHistory.value[tag]) return searchHistory.value[tag]

    const mainStore = useMainStore()
    const url = `${mainStore.origin}/api/course/_search`
    const result = await $fetch<SearchResult>(url, {
      params: { query, filters }
    })
    searchHistory.value[tag] = result?.data || []

    return result?.data || []
  }

  const checkExpiry = (courseId: string | undefined) => {
    if (courseId == null) return

    if (isExpired(createdTracker.value[courseId])) {
      delete createdTracker.value[courseId]
      const index = items.value.findIndex((item) => item?.id === courseId)
      if (index !== -1) items.value.splice(index, 1)
    }
  }

  return {
    items,
    muleCourseItems,
    searchHistory,
    createdTracker,
    getOne,
    getMuleCourseById,
    courseExists,
    muleCourseExists,
    fetchOne,
    fetchMuleCourseById,
    fetchCourseRollup,
    search
  }
})
